import React from "react";

const NotFoundPage = () => {
  return (
    <main>
      <title>Niet gevonden</title>
      <h1>Niet gevonden</h1>
      <p>Pagina is niet gevonden.</p>
    </main>
  );
};

export default NotFoundPage;
